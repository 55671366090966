<template>
  <vue-pdf :src="pdfUrl"></vue-pdf>
</template>

<script>
export default {
  props: {
    pdfUrl: String
  }
};
</script>
